import Utilities from "./Utilities";

export default class Configuration {

  constructor(configObject) {

    window.CookieConsent.buffer = {
      appendChild: [],
      insertBefore: []
    }

    // Last active element for accessibility
    window.CookieConsent.lastActiveElement = '';

    // Wrapper filter function
    window.CookieConsent.wrapper = function () { };

    // Settings injector for users
    window.CookieConsent.setConfiguration = this.setConfiguration.bind(this);

    window.CookieConsent.config = {
      active: true,
      cookieExists: false,
      cookieVersion: 1,
      modalMainTextMoreLink: null,
      barTimeout: 500,
      cookiePath: '/',
      theme: {
        barColor: '#333',
        barTextColor: '#FFF',
        barMainButtonColor: '#FFF',
        barMainButtonTextColor: '#000',
        modalMainButtonColor: '#333',
        modalMainButtonTextColor: '#FFF',
      },
      language: {
        current: 'it',
        locale: {
          it: {
            closeBar: 'Chiudi barra dei cookie',
            barMainText: '',
            barLinkSetting: 'Personalizza',
            barBtnAcceptAll: 'Accetta tutti',
            closeModal: 'Chiudi finestra di dialogo per le impostazioni cookie',
            modalMainTitle: 'Impostazione cookie',
            modalMainText: '',
            openModalTab: 'Apri',
            closeModalTab: 'Chiudi',
            switchText: 'Accetta cookie',
            modalBtnSave: 'Salva impostazioni',
            modalBtnAcceptAll: 'Accetta tutti e chiudi',
            modalAffectedSolutions: 'Servizi utilizzati',
            learnMore: 'vai alla cookie policy',
            on: 'Si',
            off: 'No',
          },
          en: {
            closeBar: 'Close cookie bar',
            barMainText: '',
            barLinkSetting: 'Customize',
            barBtnAcceptAll: 'Accept all',
            closeModal: 'Close cookie settings dialog',
            modalMainTitle: 'Cookie settings',
            modalMainText: '',
            openModalTab: 'Open',
            closeModalTab: 'Close',
            switchText: 'Accept cookie',
            modalBtnSave: 'Save settings',
            modalBtnAcceptAll: 'Accept all and close',
            modalAffectedSolutions: 'Used services',
            learnMore: 'read more',
            on: 'Yes',
            off: 'No',
          }
        }
      },
      categories: {},
      services: {}
    }
    this.setConfiguration(configObject);
  }

  setConfiguration(configObject) {
    // The user overrides the default config
    //console.log(window.CookieConsent.config, configObject, { ...window.CookieConsent.config, ...configObject });

    this.mergeDeep(window.CookieConsent.config, configObject)
    //loMerge(window.CookieConsent.config, configObject);
    // The cookie overrides the default and user config
    this.cookieToConfig();

    // We tell the world we did this
    Utilities.dispatchEvent(document, 'CCConfigSet');
  }

  cookieToConfig() {

    function removeReload() {
      Utilities.removeCookie();
      location.reload();
      return false;
    }

    document.cookie.split(';').filter((item) => {

      if (item.indexOf('cconsent') >= 0) {
        var cookieData = JSON.parse(item.split('=')[1]);

        // We check cookie version. If older we need to renew cookie.
        if (typeof cookieData.version === 'undefined') {
          return removeReload();
        } else {
          if (cookieData.version !== window.CookieConsent.config.cookieVersion) {
            return removeReload();
          }
        }

        // We check if cookie data categories also exist in user config
        for (let key in cookieData.categories) {

          // The cookie contains category not present in user config so we invalidate cookie
          if (typeof window.CookieConsent.config.categories[key] === 'undefined') {
            return removeReload();
          }
        }

        // We check if cookie data services also exist in user config
        cookieData.services.forEach(function (service) {

          // The cookie contains service not present in user config so we invalidate cookie
          if (typeof window.CookieConsent.config.services[service] === 'undefined') {
            return removeReload();
          }
        });

        // We we integrate cookie data into the global config object
        for (let key in cookieData.categories) {
          window.CookieConsent.config.categories[key].checked = window.CookieConsent.config.categories[key].wanted = (cookieData.categories[key].wanted === true) ? true : false;
        }

        window.CookieConsent.config.cookieExists = true;
        return true;
      }
    });

    return false;
  }


  // Simple object check.
  isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  //Deep merge two objects.
  mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }
}
