import Utilities from "./Utilities";
import Filter from './Filter';

export default class InsertScriptFilter extends Filter {

  constructor() {
    super();
  }

  init() {
    this.overrideAppendChild();
    this.overrideInsertBefore();
  }

  overrideAppendChild() {
    Element.prototype.appendChild = function(elem) {
      if(arguments[0].tagName === 'SCRIPT') {
				for (var service in window.CookieConsent.config.services) {
					var objectType = Utilities.objectType(window.CookieConsent.config.services[service].type);
					if ((objectType === 'String' && window.CookieConsent.config.services[service].type === 'dynamic-script') ||
							(objectType === 'Array' && window.CookieConsent.config.services[service].type.indexOf('dynamic-script') != -1)) {
						if (window.CookieConsent.config.categories[window.CookieConsent.config.services[service].category].needed === false) {
							if (window.CookieConsent.config.categories[window.CookieConsent.config.services[service].category].wanted === false) {
								var searchType = Utilities.objectType(window.CookieConsent.config.services[service].search);
								if (searchType === 'String') {
									if(arguments[0].outerHTML.indexOf(window.CookieConsent.config.services[service].search) >= 0) {
										window.CookieConsent.buffer.appendChild.push({'this': this, 'category': window.CookieConsent.config.services[service].category, arguments: arguments});
										return undefined;
									}
								} else if (searchType === 'Array') {
									for (let i = 0; i < window.CookieConsent.config.services[service].search.length; i++) {
										if(arguments[0].outerHTML.indexOf(window.CookieConsent.config.services[service].search[i]) >= 0) {
											window.CookieConsent.buffer.appendChild.push({'this': this, 'category': window.CookieConsent.config.services[service].category, arguments: arguments});
											return undefined;
										}
									}
								}
							}
						}
					}
				}
      } 
      return Node.prototype.appendChild.apply(this, arguments);
    }
  }

  overrideInsertBefore() {
    Element.prototype.insertBefore = function(elem) {
      if(arguments[0].tagName === 'SCRIPT') {
				for (var service in window.CookieConsent.config.services) {
					var objectType = Utilities.objectType(window.CookieConsent.config.services[service].type);
					if ((objectType === 'String' && window.CookieConsent.config.services[service].type === 'dynamic-script') ||
							(objectType === 'Array' && window.CookieConsent.config.services[service].type.indexOf('dynamic-script') != -1)) {
						if (window.CookieConsent.config.categories[window.CookieConsent.config.services[service].category].needed === false) {
							if (window.CookieConsent.config.categories[window.CookieConsent.config.services[service].category].wanted === false) {
								var searchType = Utilities.objectType(window.CookieConsent.config.services[service].search);
								if (searchType === 'String') {
									if(arguments[0].outerHTML.indexOf(window.CookieConsent.config.services[service].search) >= 0) {
										window.CookieConsent.buffer.appendChild.push({'this': this, 'category': window.CookieConsent.config.services[service].category, arguments: arguments});
										return undefined;
									}
								} else if (searchType === 'Array') {
									for (let i = 0; i < window.CookieConsent.config.services[service].search.length; i++) {
										if(arguments[0].outerHTML.indexOf(window.CookieConsent.config.services[service].search[i]) >= 0) {
											window.CookieConsent.buffer.appendChild.push({'this': this, 'category': window.CookieConsent.config.services[service].category, arguments: arguments});
											return undefined;
										}
									}
								}
							}
						}
					}
				}
      }
      return Node.prototype.insertBefore.apply(this, arguments);
    }
  }
}